// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allocation-input-renderer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
}
.allocation-input-renderer .ant-input-number {
  width: 50px;
}
.allocation-input-renderer .ant-input-number-input {
  padding: 5px 0;
  font-size: 12px !important;
  font-family: "RWESansWeb-Medium";
  color: #1d4477;
  text-align: center;
}
.allocation-input-renderer--error {
  background: #da8f99;
}
.allocation-input-renderer--updated {
  background: #b3e3e2;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_request_details/custom_renderer/allocation_input_renderer/allocation_input_renderer.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,uBAAA;AADF;AAJA;EAQI,WAAA;AADJ;AAEI;EACE,cAAA;EACA,0BAAA;EACA,gCAAA;EACA,cAAA;EACA,kBAAA;AAAN;AAIE;EACE,mBAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.allocation-input-renderer {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  justify-content: center;\n\n  .ant-input-number {\n    width: 50px;\n    &-input {\n      padding: 5px 0;\n      font-size: 12px !important;\n      font-family: \"RWESansWeb-Medium\";\n      color: @brand;\n      text-align: center;\n    }\n  }\n\n  &--error {\n    background: @dark-red-50;\n  }\n\n  &--updated {\n    background: @gradient-green-30;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
