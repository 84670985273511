import { FrameworkComponents } from "@src/types/aggrid_types";
import { AllocationRowData } from "@src/types/role_request_types";
import { columnTypes, groupHeaderStyleOptions } from "@src/utils/aggrid_utils";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  ColDef,
  GridOptions,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import _ from "lodash";

import { AllocationInputRenderer } from "../custom_renderer/allocation_input_renderer";

function getColDefMonth(year: number, month: number): ColDef {
  return {
    cellRendererSelector: (props: CustomCellRendererProps) =>
      props.node.rowIndex !== 0 && { component: "allocationInputRenderer" },
    valueGetter: (params: ValueGetterParams) => {
      const foundAllocation: AllocationRowData = params.data.allocations.get(
        `${month + 1}-${year}`
      );
      return (
        foundAllocation?.updatedPercentage || foundAllocation?.percentage || 0
      );
    },
    valueSetter: (params: ValueSetterParams) => {
      if (params.newValue === params.oldValue) return false;

      const allocationMap: Map<string, AllocationRowData> = _.cloneDeep(
        params.data.allocations
      );

      const foundAllocation: AllocationRowData = allocationMap.get(
        `${month + 1}-${year}`
      );
      if (foundAllocation) {
        foundAllocation.updatedPercentage = params.newValue;
      } else {
        const newAllocation: AllocationRowData = {
          percentage: 0,
          updatedPercentage: params.newValue,
        };
        allocationMap.set(`${month + 1}-${year}`, newAllocation);
      }

      params.data.allocations = allocationMap;
      return true;
    },
    valueFormatter: (params: ValueFormatterParams) => {
      const value: number = params.value || 0;
      return `${value}%`;
    },
  };
}

function getComponents(): FrameworkComponents {
  return {
    allocationInputRenderer: (props: CustomCellRendererProps) => {
      return <AllocationInputRenderer props={props} />;
    },
  };
}

export function getGridOptions(): GridOptions {
  return {
    ...groupHeaderStyleOptions,
    rowHeight: 40,
    domLayout: "autoHeight",
    suppressContextMenu: true,
    suppressColumnVirtualisation: true,
    defaultColDef: {
      ...columnTypes.default,
      resizable: false,
    },
    components: getComponents(),
    context: {
      errorColIds: [],
    },
  };
}

export function getColumnDefs(
  workloadStartDate: Date,
  workloadEndDate: Date
): ColDef[] {
  const allocationColumns: ColDef[] = getAllocationColumnDefs(
    workloadStartDate,
    workloadEndDate,
    getColDefMonth
  );
  return [
    {
      field: "label",
      headerName: "",
      sortable: false,
      pinned: "left",
      width: 320,
      cellClass: "label",
    },
    ...allocationColumns,
  ];
}
