import {
  AllocationDetails,
  ProposeAllocation,
  RequestOverviewDetails,
} from "@src/types";
import {
  AllocationRowData,
  TeamLeadAllocationRowData,
} from "@src/types/role_request_types";
import dayjs from "dayjs";
import _ from "lodash";

export function mapRowData(
  request: RequestOverviewDetails
): TeamLeadAllocationRowData[] {
  if (!request.roleAllocationDetails) {
    return [];
  }
  const currentRequest = request.childRequest ?? request;
  const allocationMap = new Map<string, AllocationRowData>();

  currentRequest.roleAllocationDetails.map((allocation: AllocationDetails) => {
    const year: number = dayjs(allocation.date).year();
    const month: number = dayjs(allocation.date).month();

    const allocationDetails: AllocationRowData = {
      percentage: allocation.requiredPercentage,
    };
    allocationMap.set(`${month}-${year}`, allocationDetails);
  });

  return [
    {
      label: "Requested Workload",
      allocations: allocationMap,
    },
    {
      label: "Choosen Vacancy",
      allocations: allocationMap,
    },
  ];
}

export function createVacancyProposeAllocationPayload(
  udatedAllocations: Map<string, AllocationRowData>
): ProposeAllocation[] {
  const proposedAllocations: ProposeAllocation[] = [];
  udatedAllocations.forEach((allocation, colId) => {
    const [month, year] = _.split(colId, "-").map((value: string) =>
      parseInt(value)
    );
    proposedAllocations.push({
      allocation: allocation.updatedPercentage,
      isAllocationRequest: true,
      requestedMonth: month,
      requestedYear: year,
      uniqueId: `${year}-${month}`,
    });
  });

  return proposedAllocations;
}
