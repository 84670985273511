import { ReactNode } from "react";

import { YearlyAllocations } from "@src/types/role_request_types";
import { ColDef, RowNode } from "ag-grid-community";
import { TransferProps } from "antd";
import { TransferItem } from "antd/es/transfer";

import { ProjectTypeEnum, SolutionType } from "./constants";
import { TabItem } from "./types/antd_types";
import { AllocationRowData } from "./types/role_request_types";

export interface Country {
  countryId: number;
  countryName: string;
  regionName?: string;
}

export interface Region {
  regionId: number;
  regionName: string;
}

export interface RequestStatusStatistics {
  requested: number;
  replied: number;
  proposed: number;
  confirmed: number;
  inRecruitment: number;
  declinedStatus: number;
  requiredAction: number;
  declinedTag: number;
  total: number;
}

export interface Employee {
  employeeId: number;
  fullName: string;
  firstName: string;
  lastName: string;
  applicationRoles: string[];
  deskId: string;
  uiNumber: string;
  rcdNumber: string;
  companyName?: string;
  positionText?: string;
  positionStartDate?: Date;
  positionEndDate?: Date;
  email: string;
  totalAvailablePercentage: number;
  contractType: string;
  costCenter?: string;
  genericRoleName?: string;
  genericRoleId?: number;
  departmentId: number;
  departmentCode: string;
  sapData: boolean;
  isDeputy: boolean;
  located?: string;
  teamLeadId: number;
  teamLeadFullName?: string;
  uiNumberBoss: string;
  primaryRoles?: number[];
}

export interface Page<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
}

export type SortDirection = "asc" | "desc";

export interface SortSpec<TField extends string> {
  field: TField;
  direction: SortDirection;
}

export interface PageableRequestParams {
  page?: number;
  size?: number;
}

export interface PageableRequest<T, U extends SortSpec<any>> {
  pageRequestParams?: PageableRequestParams;
  sortRequestParams?: U[];
  filterParams?: T;
}

export interface EmployeeSearchFilters {
  employeeIds?: number[];
  fullName?: string;
  uiNumber?: string;
  positionId?: string;
  orgCodes?: string[];
  roles?: string[];
  standardRoles?: string[];
  teamLeadFullNames?: string[];
}

export type EmployeeFields =
  | "fullName"
  | "uiNumber"
  | "positionId"
  | "orgCode"
  | "role"
  | "standardRole"
  | "teamLeadFullName";

export type EmployeeSortSpec = SortSpec<EmployeeFields>;

export type EmployeeSearchRequest = PageableRequest<
  EmployeeSearchFilters,
  EmployeeSortSpec
>;

export interface Project {
  id: number;
  name: string;
  projectPhase: string;
  countryId: number;
  country: string;
  regionName: string;
  projectManager: Employee;
  startDate: Date;
  endDate: Date;
  lastEdit: Date;
  stageGateDate: Date;
  projectEditors: Employee[];
  jointVenturePartner?: string;
  technologies: string[];
  projectType: ProjectTypeEnum;
  sendRequestsTotal: number;
  inRecruitmentRequestsTotal: number;
  outdatedRequestsTotal: number;
  openRequestsTotal: number;
  requestedRequestsTotal: number;
  confirmedRequestsTotal: number;
  proposedRequestsTotal: number;
  declinedRequestsTotal: number;
  inProgressRequestsTotal: number;
  actionNeededTotal: number;
}

export interface Department {
  departmentId: number;
  orgUnit: string;
  orgName: string;
  departmentLevel: string;
}

export interface TreeDataRequest {
  projectRoleRequestId: number;
  projectName: string;
  projectPhase: string;
  status: string;
  country: string;
}

export interface CustomTransferItem<T> extends TransferItem {
  key: string;
  data: T;
  // Add more properties if needed
}

export interface ProjectRoleRequest {
  childId: number;
  genericRoleId: number;
  genericRoleName: string;
  status: string;
  projectRoleRequestId: number;
  projectIdentifier: string;
  scope: string;
  specificRole: string;
  yearlyAllocations: any;
  proposedYearlyAllocations?: any;
  jointVenture?: boolean;
  isOutdated: boolean;
  parentRequestId?: number;
  daysUntilDeletion?: number;
}

export interface TeamLeadRequestOverviewItem {
  projectRoleRequestId: number;
  requestedRoleName: string;
  genericRole: string;
  basicRoleName: string;
  roleSpecificationName: string;
  primaryTeamLeadEmployeeId: number;
  projectName: string;
  projectPhase: string;
  country: string;
  region: string;
  requestSentDate: Date;
  status: string;
  isUpdated: boolean;
  childRequestId?: number;
  actionRequired: boolean;
  isDeclined: boolean;
  isHandedOver: boolean;
  projectIdentifier: string;
  commentReadByEmployee: boolean;
  commentAvailable: boolean;
  outdated: boolean;
  roleRequestStart: Date;
  lastUpdatedDate: Date;
  technologies: string[];
  countryName: string;
  regionName: string;
  departmentCode: string;
}

export interface TimeBlocker {
  teamMemberId: number;
  specificRole: string;
  isNewTimeBlocker: boolean;
  projectRoleRequestId: number;
  projectName: string;
  allocations: Allocation[];
}

export interface TimeBlockerRequestPayload {
  timeBlockers: Allocation[];
  timeBlockerDescription: string;
}

export interface TimeBlockerFormValue {
  value: number;
  allocations: Allocation[];
  isFieldTouched?: boolean;
}

/**
 * @deprecated use Allocation (or AllocationObject) from src/types/role_request_types.ts instead
 */
export interface Allocation {
  year: number;
  month: number;
  date: Date;
  requiredPercentage: number;
}

export interface OriginalAllocations extends Allocation {
  allocationDetailsId: number;
  projectRoleRequestId: number;
}

export interface RequestedAllocations {
  originalAllocations: OriginalAllocations[];
  transformedAllocations: any[];
  transformedEditedAllocations: string[];
}

export interface SuggestWorkloadPayload {
  id: number;
  selectedMemberId?: number;
  requestAllocationPayload: ProposeAllocation[];
}

export interface SuggestWorkloadDetails {
  hasSuggested: boolean;
  payload: UpdateRequest[];
  //check better type later
  allAllocations?: any[];
}

export interface TeamMemberAllocationDetails {
  employeeId: number;
  fullName: string;
  genericRole: GenericRole;
  projects: ProjectAllocationDetails[];
  isVacancy: boolean;
  positionText: string;
  totalAvailabilityPercentage: number;
  genericRoleName: string;
  genericRoleId: number;
}

export interface ProjectAllocationDetails {
  projectRoleRequestId: number;
  projectId: number;
  projectName: string;
  specificRole: string;
  allocations: Allocation[];
  status: string;
}

export interface ModalMsg {
  title: string;
  msg: ReactNode;
  action?: string;
  btnStatus?: boolean;
  size?: string;
  leaveCommentFor?: any;
}

/**
 * @deprecated
 */
export interface TeamMemberCapacity {
  employeeId: number;
  employeeName: string;
  firstName: string;
  lastName: string;
  freeCapacitiesDTOS: FreeCapacity[];
  genericRoleId: number;
  genericRoleName: string;
}

/**
 * @deprecated
 */
export interface FreeCapacity {
  year: number;
  month: number;
  capacity: number;
}

export interface TeamMemberCapacityV2 {
  employeeId: number;
  employeeName: string;
  standardRoleName: string;
  capacities: CapacityV2[];
}

export interface CapacityV2 {
  date: Date;
  capacity: number;
}

export interface StepProgressItem {
  title: string;
  content: ReactNode;
}

export interface SpecificationSplits {
  splits: number[];
}

export interface SentRequest extends Omit<ProjectRoleRequest, "isOutdated"> {
  key: number;
  contractType: string;
  costCenter: string;
  parentRequestId: number;
  teamLeadFullName: string;
  teamLeadOrgCode: string;
  teamLeadUiNumber: string;
  teamLeadRcdNumber: string;
  teamMemberFullName: string;
  teamMemberOrgCode: string;
  technologies: string;
  totalAvailability: number;
  uiTeamMember: string;
  outdated: boolean;
}

export interface Note {
  id?: number;
  text: string;
  employeeFullName: string;
  employeeId: number;
  roleRequestId: number;
  createdDate: string;
  createdBy: number;
}

export interface NoteHistory {
  noteHistoryId: number;
  noteId: number;
  requestId: number;
  readByEmployeeId: number;
}

export interface RequestReplies extends ProjectRoleRequest {
  primaryTeamleadEmployeeId: number;
  teamLeadFullName: string;
  assignedTeamMember: string;
  assignedTeammemberId: number;
  assignedTeammemberDTO: Employee;
  requestSentDate: Date;
  teamLead: string;
  contractType: string;
  projectDTO: Project;
  countryName: string;
  isProposalWithdrawn: boolean;
  isEmployeeDeleted: boolean;
  isReadByEmployee: boolean;
  isCommentAvailable: boolean;
  teamLeadEmployeeDTO: Employee;
  projectName: string;
  projectManager: string;
  isTlChangedOfAssignedEmployee: boolean;
  forwardedTeamlead?: string;
  isJointVenture?: boolean;
}

export interface AdminUploadRequest {
  rowNumber: number;
  basicRoleName: string;
  roleSpecificationName: string;
  givenStandardRoleName: string;
  isValidStandardRole: boolean;
  projectIdentifier?: string;
  specificRole?: string;
  scope?: string;
  standardRoleId: number;
  allocations: AdminUploadAllocation[];
}

export interface AdminUploadAllocation {
  date: string;
  allocation: string;
}

export interface LoadingMessage {
  type: string;
  percent: number;
  currentRowStatus: number;
  totalRows: number;
  text: string;
}

export interface SapCsvData {
  companyName: string;
  contractType: string;
  costCenter: string;
  departmentCode: string;
  departmentName: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  located: string;
  positionEndDate: Date;
  positionId: string;
  positionStartDate: Date;
  positionText: string;
  rcdNumber: string;
  rcdNumberBoss: string;
  totalAvailablePercentage: number;
  uiNumber: string;
  uiNumberBoss: string;
  teamLeadFullname: string;
  teamLeadOrgCode: string;
  status: string;
  errorMessage: string[];
  applicationRoles: any[];
  isTeamLead: boolean;
}

export interface ProjectPayload {
  projectId: number;
  projectManager: number;
  editors: number[];
  name: string;
  phase: string;
  countryId: number;
  startDate: string;
  endDate: string;
  stageGateDate: string;
  jointVenturePartner: string;
  technologies: string[];
  projectType: string;
}

export interface EditRequestPayload {
  projectId: number;
  editedRequests: UpdateRequest[];
}

export interface SaveRequestPayload {
  projectId: number;
  updatedRequest: UpdateRequest;
}

export interface DeleteRequest {
  requestID: number;
}

export interface UpdateRequest {
  uniqueId: string;
  requestID: number;
  originRequestId: number;
  genericRoleId: number;
  oldGenericRoleId: number;
  columnName: string;
  scope: string;
  value: string;
  projectIdentifier: string;
  requestedYear: number;
  requestedMonth: number;
  allocation: number;
  isJointVenture: boolean;
  isAllocationRequest: boolean;
}

export interface SendRequestPayload {
  roleRequestId: number;
  genericRoleId: number;
}

export interface RequestsToSplit {
  standardRoleId: number;
  requestIdsToReassign: string[];
}

export interface ReassignTeamLeadPayload {
  oldLeadId: number;
  newLeadId: number;
}

export interface ReassignProjectLeadPayload {
  oldLeadId: number;
  newProjectLeadId: number;
}

export interface SelectedLead {
  oldLeadId: number;
  newProjectLeadId?: number;
  newTeamLeadId?: number;
}

export interface RoleAssignment {
  description: string;
  roleSpecificationCategory: any;
  roleSpecificationId: number;
  roleSpecificationName: string;
  standardRoleId: number;
  teamLeadId: number;
}

export interface BasicRoleWithRoleAssignments {
  basicRoleName?: string;
  roleAssignments?: RoleAssignment[];
}

export interface NotificationsCount {
  pageTitle: string;
  notificationCount: number;
}

export interface Route {
  title: string;
  route?: string;
  rootRoute?: string;
  disabled: boolean;
  appRoles?: string[];
  notificationName?: string;
  childItems?: Route[];
}

export interface UploadStatistics {
  totalAdded: number;
  totalUpdated: number;
  totalUnchanged: number;
  totalErrors: number;
  totalDeleted: number;
}

export interface ProgressLoadingMessage {
  text: string;
  totalRows: number;
  currentRowStatus: number;
  percent: number;
  type: string;
}

export interface BasicRole {
  basicRoleId: number;
  basicRoleName: string;
  department?: Department;
  area?: Area;
  isAssignedToStandardRoles?: boolean;
}

export interface RoleSpecification {
  roleSpecificationId: number;
  roleSpecificationName: string;
  roleSpecificationCategory?: string;
  isAssignedToStandardRoles?: boolean;
}

export interface StandardRole {
  basicRole: BasicRole;
  description: string;
  assignments?: string[];
  onlyVisibleForAdmin: boolean;
  primaryTeamLeadEmployeeId: number;
  roleSpecification?: RoleSpecification;
  standardRoleId: number;
  standardRoleName: string;
  teamLeadFullName: string;
  teamLead: Employee;
}

export interface GenericRole
  extends Omit<StandardRole, "standardRoleId" | "standardRoleName"> {
  genericRoleId: number;
  genericRoleName: string;
  primaryTeamleadEmployeeFullName: string;
}

export interface StandardRoleForm {
  basicRoleId: number;
  roleSpecificationId: number;
  teamLeadId: number;
  description: string;
  visibility: boolean;
}

export interface ApplicationRole {
  applicationRoleId: number;
  applicationRoleName: string;
}

export interface EmployeeDeleteStatus {
  employeeId: number;
  fullName: string;
  teamleadAndNotDeleteable: boolean;
  projectLeadAndNotDeleteable: boolean;
  errorMessage: string[];
  deleteStatus?: string;
}

export interface MonthlyTotalFte {
  month: number;
  year: number;
  totalFte: number;
}

export interface RequestStatusStatistics {
  requested: number;
  proposed: number;
  confirmed: number;
  inRecruitment: number;
  declinedStatus: number;
  requiredAction: number;
  declinedTag: number;
  total: number;
}

export interface RequestCountryStatistics {
  countryName: string;
  totalRequests: number;
}

export interface FilterPanel {
  labels: string[];
  header: string;
  key: number;
  checkedLabels?: string[];
  filterType: string;
}

export interface ActiveFilter {
  filterType: string;
  filterValue: string;
}

export interface DashboardFilterPayload {
  managerId: number;
  monthRange?: number;
  countries?: string[];
  regions?: string[];
  projects?: string[];
  standardRoles?: string[];
}

export interface DashboardRequest {
  region: string;
  country: string;
  projectName: string;
  requestedRoleName: string;
  departmentCode: string;
}

export interface DashboardStatistics {
  countryStatistics: RequestCountryStatistics[];
  totalFte: MonthlyTotalFte[];
  requestStatusStatistics: RequestStatusStatistics;
  requests: DashboardRequest[];
}

export interface Area {
  areaId: number;
  areaName: string;
}

export interface AntdTableFilter {
  text: string;
  value: string;
}

/** payloads */

export interface ProjectPayload {
  projectId: number;
  projectManager: number;
  editors: number[];
  name: string;
  phase: string;
  countryId: number;
  startDate: string;
  endDate: string;
  stageGateDate: string;
  jointVenturePartner: string;
  technologies: string[];
  projectType: string;
}

export interface AssignRolesPayload {
  employeeId: number;
  applicationRoles: string[];
  tlPrimaryGenericRoles: number[];
  tmGenericRole: number;
}

export interface AssignTeamMemberStandardRolesPayload {
  employeeId: number;
  assignedRoleObject: { tmGenericRole: number };
}

export interface UpdateEmployeePayload {
  selectedEmployee: Employee;
  setRequestsToRequested: boolean;
}

export interface RoleAssignmentFilterData {
  basicRoles?: BasicRole[];
  roleSpecifications?: RoleSpecification[];
  standardRoles?: StandardRole[];
  numberOfErrors?: number;
}

export interface ProjectRoleRequestsOfStandardRolePayLoad {
  standardRoleId: number;
  showChildRequest: boolean;
  showTimeBlocker: boolean;
}

// resource plan types
export interface SentRequestRowNode extends RowNode<SentRequest> {
  initialData: ProjectRoleRequest;
  isProjectIdentifierUpdated: boolean;
  isSpecificRoleUpdated: boolean;
  isJointVentureUpdated: boolean;
  isGenericRoleUpdated: boolean;
  isScopeUpdated: boolean;
  diff: {
    [key: string]: AllocationDiff;
  };
}

export interface AllocationDiff {
  originRequestId: number;
  requestID: number;
  allocation: number;
}

export interface RequestSelection {
  roleRequestId: number;
  genericRoleId: number;
  isError: boolean;
}

export interface ParentChildIdMap {
  [childId: number]: {
    projectRoleRequestId: number;
  };
}

export interface AllocationDetails {
  allocationDetailsId?: number;
  date?: Date;
  month?: number;
  projectRoleRequestId?: number;
  proposedPercentage?: number;
  requiredPercentage?: number;
  year?: number;
}

// store types
export interface SplitRoleState {
  currentAssignedRequestsToSplit: Record<string, string[]>;
  unassignedRequests: TransferItem[];
  allRequestsToReassign: TransferItem[];
  targetKeys: TransferProps["targetKeys"];
  isSaveDisabled: boolean;
  isLastStepStateInitialized: boolean;
}

export interface ColumnConfig {
  key: string;
  width?: number;
}

export interface BaseTableState<T, U> {
  quickFilter: string;
  isFilterApplied: boolean;
  currentTabConfig?: TabItem;
  dateFilter?: string[];
  jointVentureFilter?: boolean;
  filterResult: T[];
  customTableFilters: U;
  columns: string[];
  columnConfigs?: ColumnConfig[];
}

export type BaseTableFilters = Record<string, string[]>;

export enum REQUEST_OVERVIEW_FILTER_TYPES {
  PROJECT_PHASE = "projectPhase",
  PROJECT_NAME = "projectName",
  REQUESTED_ROLE = "requestedRoleName",
  COUNTRY = "country",
  REGION = "region",
  STATUS = "status",
  TECHNOLOGIES = "technologies",
  REQUEST_START = "roleRequestStart",
  REQUIRED_ACTION = "actionRequired",
}

export interface RequestOverviewTableFilters extends BaseTableFilters {
  [REQUEST_OVERVIEW_FILTER_TYPES.COUNTRY]: string[];
  [REQUEST_OVERVIEW_FILTER_TYPES.REGION]: string[];
  [REQUEST_OVERVIEW_FILTER_TYPES.TECHNOLOGIES]: string[];
}

export interface ModalSettings {
  detailsModalVisible: boolean;
  activeTabKey: string;
}
export interface RequestOverviewState {
  selectedRequestId: number;
  modalSettings: ModalSettings;
  requestOverviewTableState: BaseTableState<
    TeamLeadRequestOverviewItem,
    RequestOverviewTableFilters
  >;
}

/**
 * @deprecated
 */
export interface FeatureToggleState {
  featureToggleState: {
    toggledFeatures: Record<string, boolean>;
  };
}

export interface TableSettingsCompactViewConfig {
  defaultCompacted: boolean;
  compactedColumns: string[];
  expandedColumns: string[];
}

export interface TableSettingsConfig {
  columns: ColDef[];
  selectedColumns?: string[];
  lockedColumns?: string[];
  onChange: (columns: string[]) => void;
  columnsToHide?: string[];
  defaultHiddenColumns?: string[];
  compactViewSettings?: TableSettingsCompactViewConfig;
}

export interface RequestOverviewDetails {
  assignedEmployeeEntity: any | null;
  assignedTeamMember: any | null;
  childRequest: RequestOverviewDetails | null;
  country: string;
  region: string;
  currentAssignedTeamLeadId: number;
  currentAssignedTeamLeadName: string;
  genericRole: string;
  genericRoleId: number;
  isActionRequired: boolean;
  isCurrent: boolean;
  isDeclined: boolean;
  isEmployeeDeleted: boolean;
  isHandedOver: boolean;
  isOutdated: boolean;
  isChangedOfAssignedEmployee: boolean;
  isOnlyFreeTextFieldChanged: boolean;
  isTlChangedOfAssignedEmployee: boolean;
  primaryTeamLeadEmployeeId: number;
  primaryTeamLeadEmployeeName: string;
  projectId: number;
  projectIdentifier: string;
  projectLead: string;
  projectName: string;
  projectPhase: string;
  projectRoleRequestId: number;
  proposedYearlyAllocations: any;
  requestSentDate: string;
  roleAllocationDetails: AllocationDetails[];
  scopeDescription: string;
  sendBy: Employee;
  stageGateDate: string;
  handedOverBy: Employee;
  specificRole: string;
  stageDate: string;
  status: string;
  technologies: any | null;
  yearlyAllocations: YearlyAllocations;
}

export type ProposeSolutionSettings = {
  type: SolutionType;
  label: string;
  disabled?: boolean;
  disabledTooltip?: string;
};

export interface UpdateInRecruitmentPayload {
  roleRequestId: number;
  inRecruitment: boolean;
}

/**
 * in the backend this is the old RoleRequestDTO which is the type UpdateRole here in the frontend.
 * So this is basically a subtype of UpdateRole, but for proposing allocations only these fields are used
 * hopefully this will be tidy up in EPIC 107287
 */
export type ProposeAllocation = {
  allocation: number;
  isAllocationRequest: boolean;
  requestedMonth: number;
  requestedYear: number;
  uniqueId: string;
};

export type NumericRendererUpdateState = {
  colId: string;
  value: number;
  isValid: boolean;
};

export type NextStepModalState = {
  nextStepModalVisible: boolean;
  proposedSolutionType: string | null;
  confirmationButtonDisabled: boolean;
  proposeTeamMember: {
    proposedAllocations: ProposeAllocation[] | null;
    selectedTeamMemberId: number | null;
  };
  selectedVacancy: {
    selectedVacancyId: number | null;
    proposedAllocations: Map<string, AllocationRowData> | null;
  };
  forward: {
    forwardedTeamLeadId: number | null;
  };
};
