import React, { useMemo, useRef } from "react";

import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import {
  setConfirmationButtonDisableStatus,
  setProposeVacancyAllocations,
  setSelectedVacancyId,
} from "@src/services/nextStepModalSlice";
import { useGetVacanciesQuery } from "@src/services/slices/teamLeadsApi";
import { RootState, useAppSelector } from "@src/setupStore";
import { Employee, RequestOverviewDetails } from "@src/types";
import {
  AllocationRowData,
  TeamLeadAllocationRowData,
} from "@src/types/role_request_types";
import {
  CellValueChangedEvent,
  ColDef,
  ColumnVisibleEvent,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Form, Select } from "antd";
import dayjs from "dayjs";
import "./vacancy_selection.less";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import {
  getColumnDefs,
  getGridOptions,
} from "../../utils/vacancy_selection_grid_options";
import { mapRowData } from "../../utils/vacancy_selection_utils";

const cookies = new Cookies();

interface VacancySelectionProps {
  request: RequestOverviewDetails;
}

const VacancySelection: React.FC<VacancySelectionProps> = ({ request }) => {
  const gridApi = useRef<GridApi | null>(null);

  const dispatch = useDispatch();

  const loggedInUser: Employee = cookies.get("loggedInuser");

  const workloadEndDate: Date = _.last(request.roleAllocationDetails)?.date;

  const { selectedVacancy } = useAppSelector(
    (state: RootState) => state.nextStepModalSlice
  );

  //rtk queries
  const { data: vacancies } = useGetVacanciesQuery(loggedInUser?.employeeId, {
    skip: !loggedInUser?.employeeId,
  });

  const columnDefs = useMemo<ColDef[]>(() => {
    return getColumnDefs(new Date(), workloadEndDate);
  }, [workloadEndDate]);

  const rowData = useMemo<TeamLeadAllocationRowData[]>(() => {
    return mapRowData(request);
  }, [request]);

  /**
   * Get the vacancy option label
   *
   * @param {Employee} selectedVacancy The selected vacancy
   */
  function getVacancyOptionLabel(selectedVacancy: Employee) {
    const positionStartDateString = selectedVacancy.positionStartDate
      ? dayjs(selectedVacancy.positionStartDate).format("DD/MM/YYYY") + " - "
      : "";
    const positionEndDateString = selectedVacancy.positionEndDate
      ? dayjs(selectedVacancy.positionEndDate).format("DD/MM/YYYY") + " - "
      : "";
    const positionText = `${selectedVacancy.positionText} (${selectedVacancy.deskId})`;
    const availabilityString = `${selectedVacancy.totalAvailablePercentage}%`;
    const contractType = selectedVacancy.contractType || "no contract type";

    return `${positionStartDateString}${positionEndDateString}${positionText} - ${availabilityString} - ${contractType}`;
  }

  /**
   * Function to handle the vacancy change event
   *
   * @param {number} selectedVacancyId The selected vacancy id
   */
  function onVacancyChange(selectedVacancyId: number) {
    const gridContext = gridApi.current.getGridOption("context");
    dispatch(setSelectedVacancyId(selectedVacancyId));
    dispatch(
      setConfirmationButtonDisableStatus(
        !selectedVacancyId || gridContext?.errorColIds.length > 0
      )
    );
  }

  /**
   * AG Grid Ready event
   *
   * @param {GridReadyEvent} event - AG Grid event parameter
   */
  function onGridReady(event: GridReadyEvent): void {
    gridApi.current = event.api;
  }

  /**
   * AG Grid onCellValueChanged event
   * It handes the enabling and disabling of the confirmation button and adds the proposed
   * allocations to the store.
   *
   * @param {CellValueChangedEvent} event AG Grid event parameter
   */
  function onCellValueChanged(event: CellValueChangedEvent) {
    const hasAnyInvalidValue = event.context.errorColIds.length > 0;

    const disableConfirmationButton =
      hasAnyInvalidValue || !selectedVacancy.selectedVacancyId;
    dispatch(setConfirmationButtonDisableStatus(disableConfirmationButton));

    if (!hasAnyInvalidValue) {
      const updatedAllocations: Map<string, AllocationRowData> =
        filterMapForUpdatedValues(event.data.allocations);

      dispatch(setProposeVacancyAllocations(updatedAllocations));
    }
  }

  /**
   * filters a given map for values that contain updatedPercentage
   * and returns a new map only containing these updatedPercentage values
   *
   * @param {Map<string, AllocationRowData>} allocations map that should be filtered
   * @returns the filtered map
   */
  function filterMapForUpdatedValues(
    allocations: Map<string, AllocationRowData>
  ): Map<string, AllocationRowData> {
    const allocationList = [...allocations]; // cast map to array to filter it

    const updatedAllocationList = allocationList.filter(
      ([colId, allocation]) =>
        allocation.updatedPercentage || allocation.updatedPercentage === 0
    );

    return new Map<string, AllocationRowData>(updatedAllocationList);
  }

  return (
    <div className="vacancy-selection">
      <div className="vacancy-selection__input">
        <Form layout="vertical">
          <Form.Item label="Vacancy" data-testid="suggest-vacancy-selection">
            <Select
              showSearch
              allowClear
              onChange={onVacancyChange}
              placeholder="Select vacancy"
              optionFilterProp="label"
              options={vacancies?.map((vacancy: Employee) => ({
                value: vacancy.employeeId,
                label: getVacancyOptionLabel(vacancy),
              }))}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={rowData}
          gridOptions={getGridOptions()}
          onGridReady={onGridReady}
          noRowsOverlayComponent={NoRowsOverlay}
          noRowsOverlayComponentParams={{
            text: "No requests found",
            customStyle: { marginTop: "48px" },
          }}
          onColumnVisible={(event: ColumnVisibleEvent) =>
            event.api.sizeColumnsToFit()
          }
          onCellValueChanged={onCellValueChanged}
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
};

export default VacancySelection;
