import { useState } from "react";

import { CustomCellRendererProps } from "ag-grid-react";
import { App, InputNumber } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import "./allocation_input_renderer.less";
import _ from "lodash";

interface AllocationInputRendererProps {
  props: CustomCellRendererProps;
}

const AllocationInputRenderer: React.FC<AllocationInputRendererProps> = ({
  props,
}) => {
  const { message } = App.useApp();

  const [className, setClassname] = useState<string>("");

  const colId = props.colDef.colId;

  /**
   * Handles the setting and validation of the input value.
   * props.setValue triggers the onValueChanged event of the table.
   *
   * @param {number} value the input value that represents the updated allocations
   */
  function onChange(value: number): void {
    // if value is not numeric, set it to the last value
    // don't trigger onValueChanged event
    if (isNaN(value)) {
      setClassname("");
      removeErrorFlag();
      return;
    }

    if (value < 0 || value > 100) {
      message.error("Please add a number between 0 and 100");
      setClassname("allocation-input-renderer--error");
      addErrorFlag();
      props.setValue(value);
      return;
    }

    const initialAllocationData = props.data.allocations.get(colId);
    const initialValue = initialAllocationData?.percentage;

    // check if it differs from the initial value
    if (value !== initialValue) {
      setClassname("allocation-input-renderer--updated");
    } else {
      setClassname("");
    }

    removeErrorFlag();
    props.setValue(value);
  }

  /**
   * Adds the colId to the errorColIds context
   */
  function addErrorFlag() {
    props.context.errorColIds = _.union(props.context.errorColIds, [colId]);
  }

  /**
   * Removes the colId from the errorColIds context
   */
  function removeErrorFlag() {
    _.pull(props.context.errorColIds, colId);
  }

  /**
   * Function to parse the value and remove the percentage sign from the value
   * and return the number value
   *
   * @param {string} value    The value to be parsed
   *
   * @returns {number}        The number value of the string
   */
  function parseValue(value: string): number {
    return value ? parseFloat(value.replace("%", "")) : 0;
  }

  return (
    <div
      className={`allocation-input-renderer ${className}`}
      data-testid="allocation-input-renderer"
    >
      <InputNumber
        defaultValue={props.value}
        controls={false}
        onChange={onChange}
        parser={parseValue}
        formatter={(value: valueType) => `${value}%`}
      />
    </div>
  );
};

export default AllocationInputRenderer;
