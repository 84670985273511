import { RequestOverviewDetails } from "@src/types";
import {
  Allocation,
  StaffingRequestTableData,
} from "@src/types/role_request_types";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";

/**
 * Calculates the start and end date that will be used for the allocation column definitions
 * Takes the child request, if it exists.
 *
 * @param {RequestOverviewDetails} request the request that will be considered
 * @returns start and end date of the request
 */
export function getWorkloadDates(request: RequestOverviewDetails): {
  startDate: Date | null;
  endDate: Date | null;
} {
  const currentRequest = request.childRequest ?? request;

  const workloadStartDate: Date = currentRequest.roleAllocationDetails[0]?.date;
  const workloadEndDate: Date = _.last(
    currentRequest.roleAllocationDetails
  )?.date;

  const endDate = getEndDate(workloadStartDate, workloadEndDate);

  return {
    startDate: workloadStartDate ? dayjs(workloadStartDate).toDate() : null,
    endDate: endDate ? dayjs(endDate).toDate() : null,
  };
}

/**
 * ensuring the end date for the table is at least 10 months in the future of the workload start date.
 *
 * @param workloadStartDate allocations first date
 * @param workloadEndDate allocations last date
 * @returns The adjusted project end date as a JavaScript Date object.
 */
export function getEndDate(
  workloadStartDate: Date,
  workloadEndDate: Date
): Date {
  const startDate: Date =
    !workloadStartDate || dayjs(workloadStartDate).isBefore(dayjs())
      ? new Date()
      : workloadStartDate;

  // Ensure end date is at least 10 months after the start date
  const minEndDateDayjs: Dayjs = dayjs(startDate)
    .add(9, "months")
    .endOf("month");

  return !workloadEndDate || dayjs(workloadEndDate).isBefore(minEndDateDayjs)
    ? minEndDateDayjs.toDate()
    : workloadEndDate;
}

function getAllocations(request: RequestOverviewDetails) {
  const childAllocations = request.childRequest?.roleAllocationDetails;

  if (childAllocations) {
    return childAllocations.map((childAllocation) => {
      const childDate = dayjs(childAllocation.date);
      const year = childDate.year();
      const month = childDate.month() + 1;

      // Find the parent-required percentage for this year/month
      const parentRequired = request.yearlyAllocations[year]?.[month];

      const allocation: Allocation = {
        date: dayjs(`${year}-${month}-01`).toDate(),
        requiredPercentage: childAllocation.requiredPercentage,
      };

      // If the child's requiredPercentage differs from parent's, set newRequiredPercentage
      if (childAllocation.requiredPercentage !== parentRequired) {
        allocation.newRequiredPercentage = childAllocation.requiredPercentage;
        allocation.requiredPercentage = parentRequired || 0;
      }

      return allocation;
    });
  } else if (request.yearlyAllocations) {
    return Object.entries(request.yearlyAllocations).flatMap(([year, months]) =>
      Object.entries(months).map(([month, requiredPercentage]) => {
        const allocation: Allocation = {
          date: dayjs(`${year}-${month}-01`).toDate(),
          requiredPercentage,
        };
        return allocation;
      })
    );
  }
}

/**
 * Generates row data from yearly allocations.
 *
 * @param request - The request object containing yearly allocations and proposed allocations.
 * @param isRequested - A boolean indicating whether the request is in a requested state.
 * @returns An array of allocation objects with dates and percentages.
 */
export function mapRowData(
  request: RequestOverviewDetails,
  isRequested: boolean
): StaffingRequestTableData[] {
  if (
    !request.yearlyAllocations ||
    Object.keys(request.yearlyAllocations).length === 0
  ) {
    return [];
  }

  const allocations: Allocation[] = getAllocations(request);
  // Create a map for quick lookup of requiredPercentage by date
  const allocationMap = new Map(
    allocations.map((allocation: Allocation) => [
      allocation.date.toISOString(),
      allocation.requiredPercentage,
    ])
  );

  const proposedAllocations: Allocation[] = Object.entries(
    request.proposedYearlyAllocations
  ).flatMap(([year, months]) =>
    Object.entries(months).map(([month, proposedPercentage]) => {
      const date = dayjs(`${year}-${month}-01`).toDate();
      return {
        date,
        proposedPercentage,
        proposalMatches:
          allocationMap.has(date.toISOString()) &&
          allocationMap.get(date.toISOString()) === proposedPercentage,
      };
    })
  );

  const rowData: StaffingRequestTableData[] = [
    {
      label: "Requested Workload",
      allocations,
    },
  ];

  if (!isRequested) {
    rowData.push({
      label: "Proposed",
      proposedName: request?.assignedTeamMember,
      allocations: proposedAllocations,
    });
  }

  return rowData;
}
