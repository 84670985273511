import React from "react";

import { AG_GRID_PINNED_TYPE } from "@src/constants";
import { RadioButtonRenderer } from "@src/custom_renderer/radio_button_renderer";
import { EmployeeNameRenderer } from "@src/features/staffing_request_details/custom_renderer/employee_name_renderer";
import { ProposeAllocationRenderer } from "@src/features/staffing_request_details/custom_renderer/propose_allocation_renderer";
import { SearchInput } from "@src/features/table_filtering/components/search_input";
import {
  AllocationDetails,
  CapacityV2,
  RequestOverviewDetails,
  TeamMemberCapacityV2,
} from "@src/types";
import { FrameworkComponents } from "@src/types/aggrid_types";
import { columnTypes, groupHeaderStyleOptions } from "@src/utils/aggrid_utils";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  CellClassParams,
  ColDef,
  FilterModel,
  GridOptions,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";

/**
 * Propose team member components
 * @returns {FrameworkComponents} The framework components
 */
function proposeTeamMemberComponents(): FrameworkComponents {
  return {
    nameCellRenderer: (
      props: CustomCellRendererProps<TeamMemberCapacityV2>
    ) => {
      return <EmployeeNameRenderer props={props} />;
    },
    radioButtonRenderer: (
      props: CustomCellRendererProps<TeamMemberCapacityV2>
    ) => {
      return <RadioButtonRenderer params={props} />;
    },
  };
}

/**
 * Handle the search event
 *
 * @param props     The search component properties
 * @param searchValue The search value
 */
function onSearch(
  props: CustomCellRendererProps<TeamMemberCapacityV2>,
  searchValue: string
): void {
  const filter: FilterModel = {
    [props.column.getColId()]: {
      filterType: "text",
      type: "contains",
      filter: searchValue,
    },
  };

  props.api.setFilterModel(filter);
}

/**
 * Get the column definitions for the grid
 *
 * @param requestEndDate    The request end date
 * @returns {ColDef[]} The column definitions
 */
function getColumnDefs(requestEndDate: Date): ColDef<TeamMemberCapacityV2>[] {
  return [
    {
      width: 10,
      pinned: "left",
      cellRendererSelector: (
        props: CustomCellRendererProps<TeamMemberCapacityV2>
      ) =>
        props.node.rowPinned === AG_GRID_PINNED_TYPE.TOP
          ? { component: () => <div>Requested Workload</div> }
          : { component: "radioButtonRenderer" },
      colSpan: (params: CellClassParams<TeamMemberCapacityV2>) => {
        return params.node.rowPinned && 2;
      },
    },
    {
      field: "employeeName",
      filter: "agTextColumnFilter",
      pinned: "left",
      width: 250,
      cellRenderer: "nameCellRenderer",
      headerComponent: (
        props: CustomCellRendererProps<TeamMemberCapacityV2>
      ) => (
        <SearchInput
          onChange={(searchValue: string) => onSearch(props, searchValue)}
        />
      ),
    },
    ...getAllocationColumnDefs(new Date(), requestEndDate, getColDefMonth),
  ];
}

/**
 * Get the column definition for the month
 *
 * @param year     The year
 * @param month    The month
 */
function getColDefMonth(year: number, month: number): ColDef {
  return {
    valueGetter: (params: ValueGetterParams) =>
      customValueGetter(params, year, month),
    valueFormatter: (params: ValueFormatterParams) => {
      const value = params.value || 0;
      return `${value}%`;
    },
  };
}

/**
 * Get the custom value getter
 *
 * @param {ValueGetterParams} params The value getter parameters
 * @param {number} year The year
 * @param {number} month The month
 *
 * @returns {number} The custom value
 */
export function customValueGetter(
  params: ValueGetterParams,
  year: number,
  month: number
): number {
  if (params.node.rowPinned === AG_GRID_PINNED_TYPE.TOP) {
    return getRequiredPercentage(params, year, month);
  }

  return getCapacity(params, year, month);
}

/**
 * Get the required percentage
 *
 * @param params    The value getter parameters
 * @param year      The year  to get the required percentage for
 * @param month     The month to get the required percentage for
 */
function getRequiredPercentage(
  params: ValueGetterParams<AllocationDetails[]>,
  year: number,
  month: number
): number {
  const foundAllocation: AllocationDetails = params.data.find(
    (allocation: AllocationDetails) =>
      dayjs(allocation.date).month() === month &&
      dayjs(allocation.date).year() === year
  );
  return foundAllocation?.requiredPercentage || 0;
}

/**
 * Get the capacity
 *
 * @param params    The value getter parameters
 * @param year      The year to create the capacity for
 * @param month     The month to create the capacity for
 */
function getCapacity(
  params: ValueGetterParams<TeamMemberCapacityV2>,
  year: number,
  month: number
): number {
  const currentCapacities: CapacityV2 = params.data.capacities?.find(
    (capacity: CapacityV2) =>
      dayjs(capacity.date).year() === year &&
      dayjs(capacity.date).month() === month
  );

  return currentCapacities?.capacity || 0;
}

/**
 * Render the propose time cell
 *
 * @param {CustomCellRendererProps} params The cell renderer parameters
 * @param {Date} requestEndDate The request end date
 *
 * @returns {React.ReactElement} The rendered propose time cell
 */
function getProposeAllocationRenderer(
  params: CustomCellRendererProps<TeamMemberCapacityV2>,
  requestEndDate: Date
): React.ReactElement {
  return (
    <ProposeAllocationRenderer
      params={params}
      requestEndDate={requestEndDate}
    />
  );
}

/**
 * Get the end date of the request
 *
 * @param request The request to get the end date from
 * @returns {Date} The end date of the request
 */
function getRequestEndDate(request: RequestOverviewDetails): Date {
  return new Date(
    request.roleAllocationDetails[
      request.roleAllocationDetails.length - 1
    ]?.date
  );
}

/**
 * Get the grid options
 *
 * @param request The request to get the grid options for
 * @returns {GridOptions} The grid options
 */
export function getGridOptions(request: RequestOverviewDetails): GridOptions {
  const requestEndDate: Date = getRequestEndDate(request);

  return {
    ...groupHeaderStyleOptions,
    columnDefs: getColumnDefs(requestEndDate),
    components: proposeTeamMemberComponents(),
    editType: "fullRow",
    suppressContextMenu: true,
    masterDetail: true,
    embedFullWidthRows: true,
    detailRowHeight: 40,
    animateRows: false,
    defaultColDef: {
      resizable: false,
      sortable: false,
      ...columnTypes.default,
    },
    detailCellRenderer: (
      props: CustomCellRendererProps<TeamMemberCapacityV2>
    ) => getProposeAllocationRenderer(props, requestEndDate),
  };
}
