import { months } from "@src/constants";
import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import dayjs, { Dayjs } from "dayjs";

let startDateDayjs: Dayjs;
let endDateDayjs: Dayjs;
let getColDefMonth: (year: number, month: number) => ColDef;

/**
 * public method for setting up the column definitions for the allocations
 *
 * @param {Date} startDate first date that needs to be considered for the table
 * @param {Date} endDate last date that needs to be considered for the table
 * @param {Function} getColDefMonthParam function for building the month column definition. gets the year and month as parameters to build a column definition for this date.
 *
 * @returns {ColGroupDef[]} returns an array of ColGroupDefs for the allocations table
 */
export function getAllocationColumnDefs(
  startDate: Date,
  endDate: Date,
  getColDefMonthParam?: (year: number, month: number) => ColDef
): ColGroupDef[] {
  if (!endDate || !startDate) return [];

  startDateDayjs = dayjs(startDate);
  endDateDayjs = dayjs(endDate);
  getColDefMonth = getColDefMonthParam;

  return getYearsAndMonthsColDefs();
}

/**
 * helper method for looping through the years to get all colGroupDefs.
 * every year is a colGroupDef containing colDefs of months.
 *
 * @returns {ColGroupDef[]} all yearly colGroupDefs
 */
function getYearsAndMonthsColDefs(): ColGroupDef[] {
  const resultColDefs: ColGroupDef[] = [];

  // loop through all years until project end date
  for (let i = startDateDayjs.year(); i <= endDateDayjs.year(); i++) {
    const colDef: ColGroupDef = getColGroupDefYear(i);
    resultColDefs.push(colDef);
  }

  return resultColDefs;
}

/**
 * builds the colGroupDef for the specific year
 *
 * @param {number} year year to be considered in the colGroupDef
 * @returns {ColGroupDef} colGroupDef for the year
 */
function getColGroupDefYear(year: number): ColGroupDef {
  const monthlyColDefs: ColDef[] = getMonthlyColDefs(year);

  return {
    groupId: "allocation",
    headerName: year.toString(),
    headerClass: "year-header-cell",
    children: monthlyColDefs,
  };
}

/**
 * helper method for looping through the months to get all colDefs
 *
 * @param {number} year year to be considered in the colDef
 * @returns {ColDef[]} all monthly colDefs
 */
function getMonthlyColDefs(year: number): ColDef[] {
  const monthlyColDefs: ColDef[] = [];

  const firstMonthIndex: number =
    year === startDateDayjs.year() ? startDateDayjs.month() : 0;

  const lastMonthIndex: number =
    year === endDateDayjs.year() ? endDateDayjs.month() : 11;

  // loop through all months of the year
  for (let i = firstMonthIndex; i <= lastMonthIndex; i++) {
    const defaultColDef = getDefaultColDefMonth(year, i);
    const customMonthColDef = getColDefMonth?.(year, i);

    const colDef = customMonthColDef
      ? { ...defaultColDef, ...customMonthColDef }
      : defaultColDef;

    monthlyColDefs.push(colDef);
  }

  return monthlyColDefs;
}

function getDefaultColDefMonth(year: number, monthIndex: number): ColDef {
  return {
    colId: `${monthIndex + 1}-${year}`,
    headerName: months[monthIndex],
    minWidth: 64,
    maxWidth: 64,
    headerClass: "month-header-cell",
    sortable: false,
    editable: false,
    cellClass: "allocation-cell",
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === 0 || !params.value) return "";

      return `${params.value}%`;
    },
  };
}
